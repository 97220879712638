import { config, dom, library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookSquare, faGithub } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowLeft,
  faArrowRight,
  faBan,
  faBell,
  faBold,
  faCalendar,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faClock,
  faCog,
  faCopy,
  faEdit,
  faExclamationCircle,
  faExternalLinkAlt,
  faEllipsisH,
  faGem,
  faGlobe,
  faHashtag,
  faHeadset,
  faInfoCircle,
  faItalic,
  faList,
  faListOl,
  faListUl,
  faMoon,
  faPlus,
  faRedo,
  faSortAmountDownAlt,
  faSortAmountUp,
  faSpinner,
  faSquare,
  faStar,
  faStickyNote,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnderline,
  faUndo,
  faUser,
  faExclamationTriangle,
  faPalette,
  faCubes,
  faFilter,
  faDatabase,
  faCode,
  faBook,
  faLink,
  faBorderStyle,
  faFileCode,
  faEye,
  faPencilAlt,
  faExclamation,
} from '@fortawesome/free-solid-svg-icons';

// Adds icons used in Design System.
// Other applications should import additional icons if needed.
library.add(
  faArrowLeft,
  faArrowRight,
  faBan,
  faBan,
  faBell,
  faBold,
  faCalendar,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faClock,
  faCog,
  faCopy,
  faEdit,
  faExclamationCircle,
  faExternalLinkAlt,
  faEllipsisH,
  faFacebookSquare,
  faGem,
  faGithub,
  faGlobe,
  faHashtag,
  faHeadset,
  faInfoCircle,
  faItalic,
  faList,
  faListOl,
  faListUl,
  faMoon,
  faPlus,
  faRedo,
  faSortAmountDownAlt,
  faSortAmountUp,
  faSpinner,
  faSquare,
  faStar,
  faStickyNote,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnderline,
  faUndo,
  faUser,
  faExclamationTriangle,
  faPalette,
  faCubes,
  faFilter,
  faDatabase,
  faCode,
  faBook,
  faLink,
  faBorderStyle,
  faFileCode,
  faEye,
  faPencilAlt,
  faExclamation
);

config.keepOriginalSource = false;
config.autoAddCss = false;
config.autoReplaceSvg = false;

// Load fontawesome CSS and watch the DOM for changes
// See https://fontawesome.com/v5/docs/apis/javascript/methods#dom-insertcss
const css = dom.css();

dom.insertCss(css);
dom.watch();
