import Trix from 'trix';

const underlineParser = (element) => {
  const style = window.getComputedStyle(element);
  return style['textDecoration'] === 'underline';
};

Trix.config.blockAttributes.default.tagName = 'p';
Trix.config.textAttributes.underline = { ...Trix.config.textAttributes.italic, tagName: 'u', parser: underlineParser };
Trix.config.blockAttributes.heading2 = { ...Trix.config.blockAttributes.heading1, tagName: 'h2' };
Trix.config.blockAttributes.heading3 = { ...Trix.config.blockAttributes.heading1, tagName: 'h3' };
